import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Flexbox Pitfalls and How to Avoid Them",
  "date": "2016-08-28",
  "promo": "flexbox",
  "description": "Avoid falling face first into the most common flexbox pitfalls",
  "color": "#a0da00"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Flexbox is amazing and powerful, but can feel so broken when you don't know the pitfalls and gotchas to watch out for. The emotion train of using flexbox typically goes like this:`}</p>
    <ol>
      <li parentName="ol">{`oooh flexbox looks nice, I'll try it!`}</li>
      <li parentName="ol">{`OMG it's amazing! Look Ma, no floats!`}</li>
      <li parentName="ol"><em parentName="li">{`falls face first into pitfall`}</em>{` I hate this CSS garbage!`}</li>
    </ol>
    <p>{`That's how it was for me as well. I got so frustrated I decided to dive super deep and master the flexbox beast. Since then I've helped over 10,000 devs master flexbox through answering questions and building a full on `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` Mastery Game. From this experience I've seen patterns of what things trip people up the most. Here are the most common pitfalls and how to avoid them.`}</p>
    <h2>{`1. The `}<inlineCode parentName="h2">{`flex`}</inlineCode>{` shorthand changes flexbox defaults`}</h2>
    <p>{`The most common pitfall is what happens when you use the `}<inlineCode parentName="p">{`flex`}</inlineCode>{` shorthand. See `}<a parentName="p" {...{
        "href": "/post/flex-shorthand/"
      }}>{`The Flex Shorthand Doesn't Play Nice`}</a></p>
    <h2>{`2. The `}<inlineCode parentName="h2">{`order`}</inlineCode>{` property is zero-based`}</h2>
    <p>{`Using `}<inlineCode parentName="p">{`order`}</inlineCode>{` makes items jump around in unexpected ways until you get how it actually works. See `}<a parentName="p" {...{
        "href": "/post/flexbox-order/"
      }}>{`How Flexbox Order Works`}</a></p>
    <h2>{`3. `}<inlineCode parentName="h2">{`flex-basis`}</inlineCode>{` is a curious beast`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` property is fundamental to flexbox, and it has some important/interesting interactions with other properties like `}<inlineCode parentName="p">{`width`}</inlineCode>{`, `}<inlineCode parentName="p">{`min-width`}</inlineCode>{`, and `}<inlineCode parentName="p">{`max-width`}</inlineCode>{`. See `}<a parentName="p" {...{
        "href": "/post/the-difference-between-width-and-flex-basis/"
      }}>{`The Difference Between Width and Flex Basis`}</a></p>
    <h2>{`4. `}<inlineCode parentName="h2">{`flex-wrap`}</inlineCode>{` flips your world upside down`}</h2>
    <p>{`As soon as you run out of space for all your flex items in a single line, you're going to need `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{`. This new property has a couple of gotchas, changes how items `}<em parentName="p">{`grow`}</em>{` and `}<em parentName="p">{`shrink`}</em>{`, and there's one value you'll want to avoid entirely! See `}<a parentName="p" {...{
        "href": "/post/flex-wrap"
      }}>{`Wrap Your Brain Around Flex-Wrap`}</a></p>
    {
      /*
      ## 2. when to use `align` vs `justify`
      ## 3. there is no `justify-self` property
      ## 6. wrap-reverse is only for masochists
      ## 7. sometimes flexbox isn't the right pick
      ## 8. still some bugs
      ## 9. intuition can be elusive */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      